<template>
  <div
    :style="{
      width: '99%',
      overflowX: 'scroll',
      fontSize: '1.1rem',
    }"
  >
    <div
      class="d-flex align-center mb-3"
      :style="{
        width: 'fit-content',
        gap: '20px',
      }"
    >
      <RewardItem
        v-for="reward in rewardList"
        :key="reward.rewardID"
        :reward="reward"
        @setLog="setLogReward"
        @callData="callData"
        :viewType="viewType"
      />
    </div>
    <FormReward
      :dialog="dialogReward"
      :reward="rewardSelect"
      :type="'update'"
      :rewardListType="rewardListType"
      :rewardListTypeUpdate="rewardListTypeUpdate"
      @callData="getRewardList"
      @setLog="setLogReward"
    />
  </div>
</template>

<script>
import FormReward from '@/components/Report/AchievementReward/FormReward.vue'
import RewardItem from './RewardItem.vue'
import { rewardApi } from '@/api/reward.js'
export default {
  components: { RewardItem, FormReward },
  data() {
    return {
      dialogReward: false,
      rewardSelect: {},
      rewardListType: [],
      rewardListTypeUpdate: [],
    }
  },
  setup() {
    return {}
  },
  created() {
    this.getRewardTypeList()
  },
  props: ['rewardList', 'viewType'],
  methods: {
    getRewardList() {
      this.$emit('callData')
    },
    openRewardLog(item) {
      this.setLogReward({
        openLog: true,
        reward: item,
      })
      this.rewardSelect = item
    },
    setLogReward(data) {
      this.dialogReward = data.openLog
      this.rewardSelect = data.reward
    },
    callData() {
      this.$emit('callData')
    },

    async getRewardTypeList() {
      this.loading = true
      await rewardApi.getRewardTypeList().then(res => {
        if (res && res.length > 0) {
          this.rewardListType = res

          this.rewardListTypeUpdate = res.reduce((object, item) => {
            object[item.id] = item.name
            return object
          }, {})
          this.rewardTypeID = res[0].id
        }
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
