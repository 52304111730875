import {
  ROOT
} from '@/api/constant.js'
import {
  handleResponse
} from '@/api/handle-response'
import {
  requestOptions
} from '@/api/request-options'
import {
  GET_PAYMENT_OVERALL,
  GET_PAYMENT_PAYMENTLIST,
  GET_PAYMENT_LISTCLASS,
  GET_PAYMENT_ALLOCATIONDETAIL,
  GET_STUDENT_INFORMATION_DETAIL,
  GET_STUDENT_INFORMATION_RELATIVE,
  GET_STUDENT_REGISTERD_COURSE,
  GET_STUDENT_ACCOUNT,
  GET_STUDENT_TELESALE_INFO,
  GET_STUDENT_WAITING_LIST,
  GET_STUDENT_PLACEMENT_TEST,
  GET_STUDENT_REFERRAL,
  GET_STUDENT_EMAIL_SMS,
  SET_STUDENT_VIEW,
  GET_STUDENT_VIEW,
} from './constant'

function getPaymentOverall(data) {
  return fetch(ROOT + GET_PAYMENT_OVERALL + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function setStudentView(data) {
  return fetch(ROOT + SET_STUDENT_VIEW, requestOptions.put(data))
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getStudentView(data) {
  return fetch(ROOT + GET_STUDENT_VIEW + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getPaymentList(data) {
  return fetch(ROOT + GET_PAYMENT_PAYMENTLIST + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getPaymentListClass(data) {
  return fetch(ROOT + GET_PAYMENT_LISTCLASS + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getRegistedCourse(data) {
  return fetch(ROOT + GET_STUDENT_REGISTERD_COURSE + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getStudentAccount(data) {
  return fetch(ROOT + GET_STUDENT_ACCOUNT + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getTelesaleInfo(data) {
  return fetch(ROOT + GET_STUDENT_TELESALE_INFO + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getWaitingList(data) {
  return fetch(ROOT + GET_STUDENT_WAITING_LIST + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getPTest(data) {
  return fetch(ROOT + GET_STUDENT_PLACEMENT_TEST + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getStudentReferral(data) {
  return fetch(ROOT + GET_STUDENT_REFERRAL + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getEmailSMS(data) {
  return fetch(ROOT + GET_STUDENT_EMAIL_SMS + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getPaymentAllocation(stu_userID, registrationID) {
  return fetch(ROOT + GET_PAYMENT_ALLOCATIONDETAIL + `?stu_userID=${stu_userID}&registrationID=${registrationID}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getStudentInfoDetail(data) {
  return fetch(ROOT + GET_STUDENT_INFORMATION_DETAIL + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

function getStudentInfoRelative(data) {
  return fetch(ROOT + GET_STUDENT_INFORMATION_RELATIVE + `?stu_userID=${data}`, requestOptions.get())
    .then(handleResponse)
    .then(data => {
      return data
    })
}

export const student_profile = {
  getPaymentOverall,
  getPaymentList,
  getPaymentListClass,
  getPaymentAllocation,
  getStudentInfoDetail,
  getStudentInfoRelative,
  getRegistedCourse,
  getStudentAccount,
  getTelesaleInfo,
  getWaitingList,
  getPTest,
  getStudentReferral,
  getEmailSMS,
  setStudentView,
  getStudentView,
}