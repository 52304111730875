var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    width: '99%',
    overflowX: 'scroll',
    fontSize: '1.1rem',
  })},[_c('div',{staticClass:"d-flex align-center mb-3",style:({
      width: 'fit-content',
      gap: '20px',
    })},_vm._l((_vm.rewardList),function(reward){return _c('RewardItem',{key:reward.rewardID,attrs:{"reward":reward,"viewType":_vm.viewType},on:{"setLog":_vm.setLogReward,"callData":_vm.callData}})}),1),_c('FormReward',{attrs:{"dialog":_vm.dialogReward,"reward":_vm.rewardSelect,"type":'update',"rewardListType":_vm.rewardListType,"rewardListTypeUpdate":_vm.rewardListTypeUpdate},on:{"callData":_vm.getRewardList,"setLog":_vm.setLogReward}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }