<template>
  <div>
    <template v-if="isLoading">
      <div class="pa-6">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
    </template>
    <div class="pa-6" v-if="!isLoading">
      <v-card width="500">
        <div style="background-color: #fafaff; border-radius: 12px" class="pa-3">
          <div class="d-flex justify-space-between">
            <p>Số tiền còn dư + Các khoản tăng khác:</p>
            <p>{{ overall.remainBalance ? overall.remainBalance.toLocaleString(en) : '-' }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p>Tổng số tiền học viên đã thanh toán:</p>
            <p>{{ overall.totalPaid ? overall.totalPaid.toLocaleString(en) : '-' }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p>Số tiền đã hoàn và các khoản giảm khác:</p>
            <p>{{ overall.totalRefunded ? overall.totalRefunded.toLocaleString(en) : '-' }}</p>
          </div>
          <div class="d-flex justify-space-between">
            <p>Số tiền đã phân bổ vào lớp học:</p>
            <p>
              {{ overall.totalAllocated ? overall.totalAllocated.toLocaleString(en) : '-' }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <p>Số tiền còn lại chưa phân bổ:</p>
            <p>
              {{ overall.totalUnallocated ? overall.totalUnallocated.toLocaleString(en) : '-' }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <p>Thời gian phân bổ tới:</p>
            <p>
              {{
                overall.nextAllocationTime
                  ? _dayjs(overall.nextAllocationTime).format('DD-MM-YYYY')
                  : '-'
              }}
            </p>
          </div>
        </div>
      </v-card>
      <div class="d-flex justify-space-between py-2" style="max-width: 200px">
        <h3>Thông tin thanh toán</h3>
        <v-icon class="ml-5" @click="showPaymentInfo = !showPaymentInfo">{{
          showPaymentInfo ? 'mdi-chevron-up' : 'mdi-chevron-down'
        }}</v-icon>
      </div>
      <v-data-table
        v-if="showPaymentInfo"
        :headers="headersPayment"
        :items="paymentInfo"
        :items-per-page="-1"
        hide-default-header
        hide-default-footer
        class="elevation-0 mb-5 dailyTable"
        fixed-header
        height="40vh"
        id="dailyTable"
      >
        <template v-slot:header="{}">
          <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
            <tr>
              <th
                v-for="(header, index) in rootPaymentHeaders"
                :key="index"
                style="background: #0b2585; color: #fff; text-align: center"
                :colspan="header.child.length === 0 ? 1 : header.child.length"
                :rowspan="header.child.length === 0 ? 2 : 1"
                v-html="header.text"
              ></th>
            </tr>
            <tr>
              <template v-for="(header, index) in rootPaymentHeaders">
                <th
                  :key="index + headerChild"
                  v-for="headerChild in header.child"
                  style="height: 48px !important; text-align: center"
                  v-html="headerChild"
                ></th>
              </template>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{}">
          <tbody>
            <tr v-for="(item, index) in paymentInfo" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.location }}</td>
              <td class="text-center">{{ item.paymentID }}</td>
              <td class="text-center">{{ _dayjs(item.paymentDate).format('DD/MM/YYYY') }}</td>
              <td class="text-center">{{ item.paymentType }}</td>
              <td >{{ item.description }}</td>
              <td class="text-center">{{ item.totAmt.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.totDiscountAmt.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.totActualAmt.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.studentAccountAmt.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.totMeterialFee.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.totPaidAmt.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.counselorName ? item.counselorName : '-' }}</td>
              <td >{{ item.remarks ? item.remarks : '-' }}</td>
            </tr>
          </tbody>
          <tfoot style="background-color: #f7efef">
            <tr>
              <td class="text-h6" colspan="7" style="text-align: left !important">Grand Total</td>
              <td class="tfoot-row">
                {{ filteredPayment.totalADicount.toLocaleString(en) }}
              </td>
              <td>
                {{ filteredPayment.totalAFDiscount.toLocaleString(en) }}
              </td>
              <td>
                {{ filteredPayment.totalLFee.toLocaleString(en) }}
              </td>
              <td>
                {{ filteredPayment.totalMFee.toLocaleString(en) }}
              </td>
              <td>
                {{ filteredPayment.totalFee.toLocaleString(en) }}
              </td>
              <td colspan="2" style="font-size: 16px; font-weight: bold; color: #ec0505"></td>
            </tr>
          </tfoot>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between py-2" style="max-width: 200px">
        <h3>Danh sách lớp học</h3>
        <v-icon class="ml-5" @click="showclassDetail = !showclassDetail">{{
          showclassDetail ? 'mdi-chevron-up' : 'mdi-chevron-down'
        }}</v-icon>
      </div>
      <v-data-table
        v-if="showclassDetail"
        :headers="headersClass"
        :items="classDetail"
        :items-per-page="-1"
        hide-default-header
        hide-default-footer
        class="elevation-0 mb-5 dailyTable"
        fixed-header
        height="40vh"
        id="dailyTable"
      >
        <template v-slot:header="{}">
          <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
            <tr>
              <th
                v-for="(header, index) in headersClass"
                :key="index"
                style="background: #0b2585; color: #fff; text-align: center"
                v-html="header.text"
              ></th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{}">
          <tbody>
            <tr v-for="(item, index) in classDetail" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.center }}</td>
              <td class="text-center">{{ item.classCode }}</td>
              <td class="text-center">{{ item.programme }}</td>
              <td class="text-center">{{ item.shiftTimeName }}</td>
              <td class="text-center">{{ item.actualDuration }}</td>
              <td class="text-center">{{ item.totalLesson }}</td>
              <td class="text-center">{{ _dayjs(item.actualStartDate).format('DD/MM/YYYY') }}</td>
              <td class="text-center">{{ _dayjs(item.actualEndDate).format('DD/MM/YYYY') }}</td>
              <td class="text-center">{{ item.tuitionFee.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.paidAmount.toLocaleString(en) }}</td>
              <td class="text-center">{{ item.outStanding.toLocaleString(en) }}</td>
            </tr>
          </tbody>
          <tfoot style="background-color: #f7efef">
            <tr>
              <td class="text-h6" colspan="5" style="text-align: left !important">Grand Total</td>
              <td style="text-align: center; font-weight: bold; color: #0b2585">
                {{ filteredClass.actualDuration }}
              </td>
              <td style="text-align: center; font-weight: bold; color: #0b2585">
                {{ filteredClass.totalLesson }}
              </td>
              <td colspan="2" style="font-weight: bold; color: #0b2585"></td>
              <td style="text-align: center; font-weight: bold; color: #0b2585">
                {{ filteredClass.tuitionFee.toLocaleString(en) }}
              </td>
              <td style="text-align: center; font-weight: bold; color: #0b2585">
                {{ filteredClass.paidAmount.toLocaleString(en) }}
              </td>
              <td colspan="1" style="font-weight: bold; color: #0b2585"></td>
            </tr>
          </tfoot>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div class="d-flex justify-space-between py-2" style="max-width: 200px">
        <h3>Phân bổ học phí</h3>
        <v-icon class="ml-5" @click="showallocatedFee = !showallocatedFee">{{
          showallocatedFee ? 'mdi-chevron-up' : 'mdi-chevron-down'
        }}</v-icon>
      </div>
      <v-data-table
        v-if="showallocatedFee"
        :headers="headersAllocatedFee"
        :items="allocatedFee"
        :items-per-page="-1"
        hide-default-header
        hide-default-footer
        class="elevation-0 mb-5 allocateTable"
        fixed-header
        height="40vh"
        id="allocateTable"
      >
        <template v-slot:header="{}">
          <thead style="border-radius: 20px; text-align: center" class="v-data-table-header">
            <tr>
              <th
                v-for="(header, index) in headersAllocatedFee"
                :key="index"
                style="background: #0b2585; color: #fff; text-align: center"
                v-html="header.text"
              ></th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody v-for="(item, index) in items" :key="index">
            <tr>
              <td class="text-center">
                <p style="text-align: center; font-weight: bold; display: inline-block">
                  Class code:
                </p>
                {{ item.classCode }}
              </td>
              <td class="text-center">
                <p style="text-align: center; font-weight: bold; display: inline-block">Program:</p>
                {{ item.programme }}
              </td>
              <td class="text-center">
                <p style="text-align: center; font-weight: bold; display: inline-block">
                  Location:
                </p>
                {{ item.location }}
              </td>
              <td class="text-center">
                <p style="text-align: center; font-weight: bold; display: inline-block">Số buổi:</p>
                {{ item.allocateDetail.length }}
              </td>
              <td class="text-center">
                <p style="text-align: center; font-weight: bold; display: inline-block">Số tiền:</p>
                {{ item.paidAmount.toLocaleString(en) }}
              </td>
              <td class="text-center">
                <p style="text-align: center">
                  <v-icon class="ml-5" @click="changeHiddenSup(item.registrationID)">{{
                    listHidden.includes(item.registrationID) ? 'mdi-chevron-up' : 'mdi-chevron-down'
                  }}</v-icon>
                </p>
              </td>
              <td class="text-center">
                <p style="text-align: center"></p>
              </td>
            </tr>
            <tr class="row-sale" v-for="(item2, index) in item.allocateDetail" :key="index">
              <td
                style="text-align: center"
                v-if="listHidden.includes(item2.registrationID) === true"
              >
                {{ item2.lessonNo }}
              </td>
              <td
                style="text-align: center"
                v-if="listHidden.includes(item2.registrationID) === true"
              >
                {{ _dayjs(item2.date).format('DD/MM/YYYY') }}
              </td>
              <td
                style="text-align: center"
                v-if="listHidden.includes(item2.registrationID) === true"
              >
                {{ item2.duration }}
              </td>
              <td
                style="text-align: center"
                v-if="listHidden.includes(item2.registrationID) === true"
              >
                {{ item2.feePerHour.toLocaleString(en) }}
              </td>
              <td
                style="text-align: center"
                v-if="listHidden.includes(item2.registrationID) === true"
              >
                {{ item2.discountPct }}
              </td>
              <td
                style="text-align: center"
                v-if="listHidden.includes(item2.registrationID) === true"
              >
                {{ item2.totalAmount.toLocaleString(en) }}
              </td>
              <td
                style="text-align: center"
                v-if="listHidden.includes(item2.registrationID) === true"
              >
                {{ item2.remarks ? item2.remarks : '-' }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </div>
  </div>
</template>
<script>
import { student_profile } from '@/api/student-profile.js'
import dayjs from 'dayjs'
export default {
  props: ['userID'],
  data() {
    return {
      overall: null,
      paymentInfo: null,
      classDetail: null,
      allocatedFee: null,
      showPaymentInfo: false,
      showclassDetail: false,
      showallocatedFee: false,
      openFeeDetail: false,
      isLoading: false,
      listHidden: [],
      rootPaymentHeaders: [
        { text: 'STT<br/>(No.)', child: [] },
        { text: 'Trung tâm</br>(Location)', child: [] },
        { text: 'Số chứng từ</br>(PaymentID)', child: [] },
        { text: 'Ngày thanh toán</br>(Date)', child: [] },
        { text: 'Hình thức thanh toán</br>(Type)', child: [] },
        { text: 'Mô tả</br>(Description)', child: [] },
        { text: 'Số tiền</br>(Amount)', child: [] },
        { text: 'Giảm giá</br>(Discount Amount)', child: [] },
        { text: 'Số tiền sau giảm giá</br>(Amount after discount)', child: [] },
        {
          text: 'Thanh toán</br>(Payment)',
          child: [
            'Học phí</br>(Only course)',
            'Học liệu</br>(Course material)',
            'Tổng số tiền</br>(Total amount)',
          ],
        },
        { text: 'NV tư vấn</br>(Counselor)', child: [] },
        { text: 'Ghi chú</br>(Remarks)', child: [] },
      ],
      headersPayment: [
        { text: 'STT<br/>(No.)', align: 'center', value: 'no', sortable: false },
        { text: 'Trung tâm</br>(Location)', align: 'center', value: 'center', sortable: false },
        {
          text: 'Số chứng từ</br>(PaymentID)',
          value: 'invoiceNum',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Ngày thanh toán</br>(Date)',
          value: 'paymentDate',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Hình thức thanh toán</br>(Type)',
          value: 'paymentType',
          align: 'center',
          sortable: false,
        },
        { text: 'Mô tả</br>(Description)', value: 'paymentDesc', align: 'center', sortable: false },
        { text: 'Số tiền</br>(Amount)', value: 'paidAmount', align: 'center', sortable: false },
        {
          text: 'Giảm giá</br>(Discount Amount)',
          value: 'amountDiscount',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Số tiền sau giảm giá</br>(Amount after discount)',
          value: 'amountAfterDiscount',
          align: 'center',
          sortable: false,
        },
        { text: 'Học phí</br>(Only course)', value: 'lessonFee', align: 'center', sortable: false },
        {
          text: 'Học liệu</br>(Course material)',
          value: 'materialFee',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Tổng số tiền</br>(Total amount)',
          value: 'totalFee',
          align: 'center',
          sortable: false,
        },
        {
          text: 'NV tư vấn</br>(Counselor)',
          value: 'counselorName',
          align: 'center',
          sortable: false,
        },
        { text: 'Ghi chú</br>(Remarks)', value: 'remark', align: 'center', sortable: false },
      ],
      headersClass: [
        { text: 'STT<br/>(No.)', align: 'center', value: 'no', sortable: false },
        { text: 'Trung tâm</br>(Location)', align: 'center', value: 'center', sortable: false },
        {
          text: 'Mã lớp</br>(Class Code)',
          value: 'classCode',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Chương trình</br>(Program)',
          value: 'paymentDate',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Ca học</br>(Class Shift)',
          value: 'shiftTimeName',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Số giờ đăng ký</br>(Duration)',
          value: 'actualDuration',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Số buổi</br>(No. Lesson)',
          value: 'totalLesson',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Ngày bắt đầu</br>(Actual Start Date)',
          value: 'actualStartDate',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Ngày kết thúc</br>(Actual End Date)',
          value: 'actualEndDate',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Học phí</br>(Only course)',
          value: 'tuitionFee',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Đã thanh toán</br>(Course material)',
          value: 'paidAmount',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Còn nợ</br>(Outstanding)',
          value: 'outStanding',
          align: 'center',
          sortable: false,
        },
      ],
      headersAllocatedFee: [
        { text: 'Buổi học</br>(Lesson)', align: 'center', value: 'lessonNo', sortable: false },
        {
          text: 'Ngày học</br>(date)',
          value: 'date',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Thời lượng buổi học</br>(Lesson Duration)',
          value: 'lessonDuration',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Học phí / Giờ</br>(Fee / hour)',
          value: 'feePerHour',
          align: 'center',
          sortable: false,
        },
        { text: 'Tỷ lệ ưu đãi</br>(Disc.Pct)', value: 'discPct', align: 'center', sortable: false },
        { text: 'Đã thanh toán</br>(Paid)', value: 'paidAmount', align: 'center', sortable: false },
        {
          text: 'Ghi chú</br>(Note)',
          value: 'lessonNote',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  created() {
    this.getPaymentOverall()
    this.getPaymentList()
    this.getPaymentClass()
  },
  computed: {
    filteredPayment() {
      let grandTotal = {
        totalADicount: 0,
        totalAFDiscount: 0,
        totalLFee: 0,
        totalMFee: 0,
        totalFee: 0,
      }
      this.paymentInfo.forEach(d => {
        grandTotal.totalADicount += d.totDiscountAmt
        grandTotal.totalAFDiscount += d.totActualAmt
        grandTotal.totalLFee += d.studentAccountAmt
        grandTotal.totalMFee += d.totMeterialFee
        grandTotal.totalFee += d.totPaidAmt
      })
      grandTotal.totalADicount = Number(grandTotal.totalADicount.toFixed(0))
      grandTotal.totalAFDiscount = Number(grandTotal.totalAFDiscount.toFixed(0))
      grandTotal.totalLFee = Number(grandTotal.totalLFee.toFixed(0))
      grandTotal.totalMFee = Number(grandTotal.totalMFee.toFixed(0))
      grandTotal.totalFee = Number(grandTotal.totalFee.toFixed(0))
      return grandTotal
    },
    filteredClass() {
      let grandTotal = {
        actualDuration: 0,
        totalLesson: 0,
        tuitionFee: 0,
        paidAmount: 0,
      }
      this.classDetail.forEach(d => {
        grandTotal.actualDuration += d.actualDuration
        grandTotal.totalLesson += d.totalLesson
        grandTotal.tuitionFee += d.tuitionFee
        grandTotal.paidAmount += d.paidAmount
      })
      grandTotal.actualDuration = Number(grandTotal.actualDuration.toFixed(0))
      grandTotal.totalLesson = Number(grandTotal.totalLesson.toFixed(0))
      grandTotal.tuitionFee = Number(grandTotal.tuitionFee.toFixed(0))
      grandTotal.paidAmount = Number(grandTotal.paidAmount.toFixed(0))
      return grandTotal
    },
  },
  methods: {
    changeHiddenSup(subItem) {
      if (this.listHidden.includes(subItem)) {
        this.listHidden = this.listHidden.filter(function (item) {
          return item !== subItem
        })
      } else {
        this.listHidden = [...this.listHidden, subItem]
      }
    },
    async getPaymentOverall() {
      this.isLoading = true
      await student_profile.getPaymentOverall(this.userID).then(res => {
        this.overall = res[0]
        this.isLoading = false
      })
    },
    async getPaymentList() {
      this.isLoading = true
      await student_profile.getPaymentList(this.userID).then(res => {
        this.paymentInfo = res.map(el => {
          return {
            ...el,
            totMeterialFee: Number(el.totActualAmt) - Number(el.studentAccountAmt),
          }
        })
        this.isLoading = false
      })
    },
    async getPaymentClass() {
      this.isLoading = true
      try {
        const paymentList = await student_profile.getPaymentListClass(this.userID)
        if (paymentList) {
          const promises = paymentList.map(async el => {
            const allocation = await student_profile.getPaymentAllocation(
              this.userID,
              el.registrationID
            )
            return {
              ...el,
              allocateDetail: allocation,
            }
          })
          const classList = paymentList.map(el => {
            return {
              ...el,
              outStanding: el.tuitionFee && el.paidAmount ? el.tuitionFee - el.paidAmount : '-',
              center: el.location,
              actualDuration: Number(el.actualDuration),
              totalLesson: Number(el.actualDuration) / Number(el.hoursPerLesson),
            }
          })
          this.allocatedFee = await Promise.all(promises)
          this.classDetail = await classList
        }
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },

    _dayjs: dayjs,
  },
}
</script>
<style lang="scss" scoped>
.headTitle {
  background-color: #0b2585 !important;
  text-align: center !important;
  font-weight: 700 !important;
  font-family: 'Helvetica' !important;
  font-size: 14px !important;
}

.dailyTable th,
td {
  border: 1px solid #dae3e8;
}
.dailyTable tbody tr:nth-child(odd) {
  background-color: #f3faff;
}
.dailyTable tbody tr:nth-child(odd):hover td {
  background-color: #dbecf8;
}
.dailyTable .date-filter {
  background-color: #eaeaea !important;
}
.dailyTable .date-filter:hover td {
  background-color: #eaeaea !important;
}

.date-filter td {
  border: 1px solid #eaeaea !important;
}
.allocateTable th,
td {
  border: none;
}
.row-sale {
  background: #fafaff;
}
tfoot tr td {
  text-align: center !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #ec0505 !important;
}
</style>
